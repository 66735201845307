import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { EnvironmentProviders, Provider } from '@angular/core';
import { httpInterceptor } from './http.interceptor';
import { MatSnackBar } from '@angular/material/snack-bar';

export const provideHttpInterceptor = (): Array<Provider | EnvironmentProviders> => {
  return [
    MatSnackBar,/* add snackbar because its used by the interceptor */
    provideHttpClient(withInterceptors([httpInterceptor]),),
  ];
};
