import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { CommonService } from '@services/common/common.service';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { StorageUtilsService } from '@services/storage-utils/storage-utils.service';
import { Router } from '@angular/router';
import { SnackbarTypes } from '@services/common/common.types';
import { Constants } from '../data/constants';


/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const httpInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const _storageUtilsService = inject(StorageUtilsService);
  const _commonService = inject(CommonService);
  let modified = request;
  const router = inject(Router);
  const token = _storageUtilsService.getAccessToken();

  if (request.headers.has(Constants.AVOID_INTERCEPTOR_HEADER)) {
    // Pass the request through without intercepting it
    return next(request);
  }

  if (token) {
    modified = request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  return next(modified).pipe(
    retry(0),
    catchError((error: HttpErrorResponse) => {
      switch (error.status) {
        case 400: {
          if (error.error && error.error.errorKey) {
            showErrorSnackbar({ errorMessage: 'errors.' + error.error.errorKey, commonService: _commonService })
          } else {
            showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage400', commonService: _commonService })
          }
          break;
        }

        case 401:
          if (error.error && (error.error.message || error.error.errorKey)) {
            showErrorSnackbar({ errorMessage: 'errors.' + error.error.message ? 'errors.' + error.error.message.split('.')[1] : error.error.errorKey, commonService: _commonService })
          } else {
            showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage401', commonService: _commonService })
            _storageUtilsService.removeAccessToken();
            if (modified.url.includes(Constants.LOGIN_ROUTE)) {
              location.reload();
            } else {
              router.navigate([Constants.LOGIN_ROUTE]);
            }
          }

          break;
        case 403:
          if (error.error && error.error.errorKey) {
            showErrorSnackbar({ errorMessage: 'errors.' + error.error.errorKey, commonService: _commonService })
          } else {
            showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage403', commonService: _commonService })
          }
          break;
        case 404:
          if (error.error && error.error.errorKey) {
            showErrorSnackbar({ errorMessage: 'errors.' + error.error.errorKey, commonService: _commonService })
          } else {
            showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage404', commonService: _commonService })
          }
          break;

        case 405:
          if (error.error && error.error.errorKey) {
            showErrorSnackbar({ errorMessage: 'errors.' + error.error.errorKey, commonService: _commonService })
          } else {
            showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage405', commonService: _commonService })
          }
          break;

        case 500:
          showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage500', commonService: _commonService })
          break;
        case 502:
          showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage502', commonService: _commonService })
          break;
        case 504:
          if (error.error && error.error.errorKey) {
            showErrorSnackbar({ errorMessage: 'errors.' + error.error.errorKey, commonService: _commonService })
          } else {
            showErrorSnackbar({ errorMessage: 'errors.standardErrorMessage504', commonService: _commonService })
          }
          break;
        default:
          showErrorSnackbar({ errorMessage: 'errors.unknown_error', commonService: _commonService })
          break;
      }
      return throwError(error);
    })
  );
}

const showErrorSnackbar = ({ errorMessage, commonService }: { errorMessage: string, commonService?: CommonService }) => {
  commonService?.showSnackBar({ message: errorMessage, snackBarType: SnackbarTypes.errorMessage, });
}


