<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
            <!-- Logo -->
            <div class="flex items-center mx-2 lg:mr-8 rounded-full  max-h-12 max-w-fit cursor-pointer">
                <div class="hidden lg:flex">
                    <img
                        class="dark:hidden min-w-24 max-w-fit max-h-18"
                        src="assets/images/logos/logo.png">
                    <img
                        class="hidden dark:flex min-w-24 max-w-fit max-h-16"
                        src="assets/images/logos/logo.png">
                </div>
                <img
                    class="flex lg:hidden min-w-24 max-w-36 max-h-18"
                    src="assets/images/logos/logo.png">
            </div>
            <!-- Horizontal navigation -->
            <fuse-horizontal-navigation
                class="mr-2"
                [name]="'mainNavigation'"
                [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
        <!-- Navigation toggle button -->

        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <languages></languages>
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary"> <span> {{copyrightName}} </span>  &copy; {{currentYear}}</span>
    </div>

</div>
