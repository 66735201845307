import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@auth-library/core/auth/auth.service';
import { Constants } from '@auth-library/core/data/constants';
import { of, switchMap } from 'rxjs';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);

    // Check the authentication status
    return inject(AuthService).check().pipe(
        switchMap((authenticated) =>
        {
            // If the user is not authenticated...
            if ( !authenticated )
            {
                // Redirect to the sign-in page with a redirectUrl param
                // const redirectURL = state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
                // const urlTree = router.parseUrl(`login?${redirectURL}`);
                const urlTree = router.parseUrl(Constants.LOGIN_ROUTE);
                urlTree.queryParams = route.queryParams;
                return of(urlTree);
            }

            // Allow the access
            return of(true);
        }),
    );
};
