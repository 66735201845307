import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  template:'<router-outlet></router-outlet>',
  styles : `:host {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
  }
`
})
export class AppComponent {
  title = 'sirh-auth-bp';
}
