import { Constants } from '@auth-library/core';
import { Route } from '@angular/router';
import { LayoutComponent } from '@auth-library/layout/layout.component';
import { initialDataResolver } from './auth.resolvers';
import { AuthGuard } from '@auth-library/core/auth/guards/auth.guard';
import { ResetPasswordGuard } from '@auth-library/core/auth/guards/reset-password.guard';
import { NoAuthGuard } from '@auth-library/core/auth/guards/noAuth.guard';


export const createRoutes = (homeComponent?: any ): Route[] =>  [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: Constants.HOME_ROUTE },

  // Redirect signed-in user to the '/example'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  // TODO to uncomment this
 /*
  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    redirectTo: Constants.HOME_ROUTE,
  },
  {
    path: Constants.HOME_ROUTE,
    component: homeComponent,
  }
  */
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'modern',
    },
    resolve: {
      initialData: initialDataResolver,
    },
    children: homeComponent ? [ { path: Constants.HOME_ROUTE,component:homeComponent }]
     : [{path: Constants.HOME_ROUTE, loadChildren : () => import('@modules/admin/home/default-home.routes'), }]
  },


  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: Constants.FORGOT_ROUTE,
        loadChildren: () => import('@modules/auth/forgot-password/forgot-password.routes'),
      },
      {
        path: Constants.RESET_PASSWORD_ROUTE,
        loadChildren: () => import('@modules/auth/reset-password/reset-password.routes'),
        canActivate: [ResetPasswordGuard],
      },
      {
        path: Constants.GUEST_REGISTER_ROUTE,
        loadChildren: () =>
          import('@modules/auth/guest-register/guest-register.routes'),
        canActivate: [ResetPasswordGuard],
      },
      {
        path: Constants.REGISTER_ROUTE,
        loadChildren: () => import('@modules/auth/sign-up/sign-up.routes'),
      },
      {
        path: Constants.ACTIVATE_ACCOUNT_ROUTE,
        loadChildren: () =>
          import(
            '@modules/auth/account-activation/account-activation.routes'
          ),
      },
      {
        path: Constants.LOGIN_ROUTE,
        loadChildren: () => import('@modules/auth/sign-in/sign-in.routes'),
      },
    ],
  },


  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: Constants.LOGOUT_ROUTE,
        loadChildren: () => import('@modules/auth/sign-out/sign-out.routes'),
      },
    ],
  },

  // Admin routes


  // Errors pages
  {
    path: '**',
    redirectTo: Constants.NOT_FOUND_ROUTE,
  },
  {
    path: Constants.NOT_FOUND_ROUTE,
    loadChildren: () => import('@modules/errors/error-404/error-404.routes'),
  },
];
