import { AuthConfig } from '@main-auth-config';
import { createProviders } from '@auth-library/config';
import { createRoutes } from '@auth-library/config';
import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appEnv } from '../environments/sirh-auth';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';


export const appConfig: ApplicationConfig = {
  providers: [
      provideRouter(createRoutes()),
      ...createProviders(),
      { provide: RECAPTCHA_V3_SITE_KEY, useValue: appEnv.ENDPOINTS.RECAPTCHA_KEY },
      {
        provide: AuthConfig,
        useFactory: () =>
          new AuthConfig({
            title: 'SIRH',
            appName: 'BP',
            poweredBy: 'TI',
            copyRightUrlRedirection: '#',
            portalSiteUrl: appEnv.ENDPOINTS.SITE_URL,
            customFeatures: null,
            cguUrl: appEnv.ENDPOINTS.CGU_URL,
            applicationId:appEnv.APPLICATION_ID,
          },
          ),
      },
  ],
};
